<template>
  <div class="introb1">
    <div class="introb1__wrapper data fullscreen">
      <div class="introb1__content">
        <h1 class="introb1__h1 h1">IMPRESSUM:</h1>

        <p class="p introb1__p">
          Angaben gemäß § 5 TMG: Gabriel Mc Closkey KFZ Meister Moser 2 88239
          Wangen Öffnungszeiten: Montag bis Freitag 8.00 bis 12.00 Uhr 13.00 bis
          17.00 Uhr Kontakt: Telefon: 017670459888 E-Mail: gabriel.m88@web.de
          Steuernummer: Umsatzsteuer-Identifikationsnummer gemäß §27 a
          Umsatzsteuergesetz: DE 303 340 896 Aufsichtsbehörde: Handwerkskammer
          Ulm Olgastraße 72 89073 Ulm Verantwortlich für den Inhalt nach § 55
          Abs. 2 RStV: Gabriel Mc Closkey Moser 2 88239 Wangen Design & Technik
          Design & Technische Realisation: www.bodenseespezial-media.de
          Streitschlichtung Wir sind nicht bereit oder verpflichtet, an
          Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
          teilzunehmen. Haftung für Inhalte Als Diensteanbieter sind wir gemäß §
          7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
          Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
          Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
          forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen. Haftung für Links Unser Angebot enthält Links zu
          externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss
          haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
          übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
          jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
          verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
          Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt
          der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle
          der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
          Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
          Rechtsverletzungen werden wir derartige Links umgehend entfernen.
          Urheberrecht Die durch die Seitenbetreiber erstellten Inhalte und
          Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die
          Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
          außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
          Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien
          dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch
          gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber
          erstellt wurden, werden die Urheberrechte Dritter beachtet.
          Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten
          Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
          bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
          Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          Quelle: e-recht24.de
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss"></style>
